<template>
  <b-carousel-item>
    <section id="maximus" class="hero is-fullheight is-dark">
      <div class="hero-body slide-body has-text-black is-relative">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 823 684" style="enable-background:new 0 0 823 684;" xml:space="preserve" class="is-absolute">
          <defs>
            <clipPath id="clip-path">
              <rect class="no-fill" x="46.46" y="135.17" width="273.28" height="68.68"/>
            </clipPath>
          </defs>
          <g id="maximus-layer">
            <g id="triangle-lines_4_" class="animate__animated animate__fadeInTopLeft _2">
              <g>
                <path class="yellow" d="M145.85,187.68c-2.45,2.8-4.91,5.6-7.36,8.41c-7.82,8.95-15.63,17.92-23.45,26.88l2.98,9.7
                  c12.61-14.44,25.22-28.89,37.84-43.35L145.85,187.68z"/>
              </g>
              <g>
                <path class="yellow" d="M167.22,191.17c-1.19,1.36-2.38,2.72-3.57,4.08c-14.09,16.11-28.18,32.23-42.26,48.34l3.28,10.65
                  c17.83-20.42,35.66-40.85,53.5-61.29L167.22,191.17z"/>
              </g>
              <g>
                <path class="yellow" d="M189.47,194.8c-3.28,3.75-6.55,7.5-9.83,11.25c-12.53,14.35-25.02,28.73-37.55,43.09
                  c-4.68,5.36-9.36,10.72-14.04,16.07l3.39,11.01c23.13-26.5,46.28-53.02,69.46-79.56L189.47,194.8z"/>
              </g>
              <g>
                <path class="yellow" d="M101.99,180.52l2.75,8.92c2.16-2.47,4.32-4.94,6.48-7.42L101.99,180.52z"/>
              </g>
              <g>
                <path class="yellow" d="M123.99,184.11c-5.15,5.9-10.3,11.8-15.45,17.69l3.14,10.2c7.62-8.72,15.23-17.44,22.85-26.17L123.99,184.11
                  z"/>
              </g>
              <g>
                <path class="yellow" d="M366.53,223.69c-9.85,11.28-19.71,22.57-29.57,33.85c-12.62,14.44-25.27,28.86-37.9,43.3
                  c-11.92,13.63-23.81,27.28-35.72,40.91c-8.6,9.85-17.22,19.69-25.82,29.54c-14.02,16.07-28.03,32.14-42.05,48.21
                  c-4.92,5.64-9.83,11.29-14.75,16.93l3.05,9.91c6.8-7.77,13.62-15.54,20.41-23.32c22.53-25.82,45.03-51.66,67.56-77.48
                  c5.32-6.09,10.64-12.18,15.95-18.27l86.36-102.36L366.53,223.69z"/>
              </g>
              <g>
                <path class="yellow" d="M199.38,242.39c-13.98,16.02-27.93,32.07-41.91,48.09c-5.33,6.12-10.67,12.23-16.01,18.34l3.05,9.91
                  c1.43-1.64,2.86-3.28,4.3-4.93c25.4-29.1,50.82-58.19,76.23-87.29c6.6-7.56,13.2-15.12,19.81-22.68l-10.3-1.68
                  C222.82,215.57,211.09,228.97,199.38,242.39z"/>
              </g>
              <g>
                <path class="yellow" d="M252.31,281.81c18.35-21.02,36.74-42.01,55.1-63.02c1.21-1.38,2.41-2.77,3.62-4.15l-10.72-1.75
                  c-11.27,12.93-22.53,25.87-33.8,38.8c-16.84,19.3-33.71,38.57-50.55,57.86c-15.35,17.58-30.66,35.19-46.01,52.78
                  c-2.96,3.39-5.93,6.77-8.89,10.16l3.15,10.22c10.27-11.77,20.59-23.49,30.86-35.25C214.15,325.58,233.21,303.68,252.31,281.81z"
                />
              </g>
              <g>
                <path class="yellow" d="M212.23,198.51c-0.18,0.21-0.37,0.42-0.55,0.63c-8.95,10.25-17.92,20.49-26.87,30.75
                  c-16.67,19.1-33.33,38.21-49.99,57.31l3.27,10.64c28.38-32.5,56.75-65.01,85.15-97.53L212.23,198.51z"/>
              </g>
              <g>
                <path class="yellow" d="M211.85,356.53c14.12-16.17,28.22-32.36,42.33-48.53c14.19-16.26,28.4-32.52,42.6-48.78
                  c11.95-13.69,23.89-27.37,35.84-41.06l-10.31-1.68c-0.45,0.51-0.89,1.02-1.34,1.54c-16.27,18.64-32.55,37.27-48.82,55.9
                  c-14.38,16.47-28.74,32.96-43.11,49.43c-13.44,15.4-26.9,30.79-40.35,46.19c-7.04,8.06-14.07,16.12-21.11,24.17l3.07,9.98
                  C184.38,387.97,198.12,372.25,211.85,356.53z"/>
              </g>
              <g>
                <path class="yellow" d="M266.54,323.3c15.18-17.38,30.33-34.77,45.51-52.15c14.36-16.44,28.72-32.88,43.08-49.32l-11.15-1.82
                  c-14.85,17-29.71,33.99-44.56,50.99c-19.58,22.41-39.16,44.83-58.73,67.26c-15.44,17.69-30.86,35.39-46.3,53.07
                  c-6.79,7.77-13.58,15.54-20.37,23.3l3.31,10.77c11.24-12.87,22.47-25.74,33.71-38.61C229.54,365.62,248.05,344.47,266.54,323.3z"
                />
              </g>
              <g>
                <path class="yellow" d="M216.6,251.02c-15.74,18.04-31.51,36.05-47.25,54.09c-7.16,8.2-14.31,16.41-21.46,24.61l3.32,10.79
                  c5.99-6.86,11.98-13.73,17.97-20.59c21.75-24.91,43.52-49.8,65.26-74.7c10.97-12.57,21.94-25.14,32.91-37.71l-11.2-1.83
                  C242.97,220.79,229.79,235.91,216.6,251.02z"/>
              </g>
              <g>
                <path class="yellow" d="M244.91,248.01c-9.97,11.42-19.91,22.87-29.89,34.29c-10.18,11.66-20.4,23.3-30.59,34.96
                  c-9.96,11.4-19.9,22.82-29.85,34.23l3.08,10.02c17.27-19.78,34.53-39.57,51.8-59.35c21.3-24.4,42.6-48.81,63.91-73.2
                  c5.22-5.97,10.44-11.93,15.66-17.9l-10.32-1.68c-2.44,2.8-4.88,5.6-7.33,8.4C262.58,227.85,253.73,237.91,244.91,248.01z"/>
              </g>
            </g>
            <g id="max-blue-triangle" class="animate__animated animate__fadeIn _3">
              <polygon class="black" points="260.53,55.8 260.53,256.69 413.01,256.69 		"/>
              <polygon class="blue black-stroke small-stroke" points="238.02,40.45 238.02,241.33 390.51,241.33 		"/>
            </g>
            <g id="max-pink-rect" class="animate__animated animate__fadeInRight _3">
              <rect x="658.11" y="242.65" class="black" width="155.93" height="426.7"/>
              <rect x="644.54" y="224.27" class="red black-stroke small-stroke" width="155.93" height="426.7"/>
            </g>
            <g id="circle_7_" class="animate__animated animate__fadeInTopRight _3">
              <circle class="black" cx="714.74" cy="109.09" r="77.81"/>
              <circle class="blue black-stroke small-stroke" cx="703.33" cy="93.16" r="77.81"/>
            </g>
            <g id="max-text-white" class="animate__animated animate__fadeIn _4">
              <rect x="355.31" y="102.19" class="black" width="373.97" height="548.78"/>
              <g>
                <rect x="337.67" y="81.47" class="white" width="369.97" height="544.78"/>
                <path class="black"
                      d="M705.64,83.47v540.78H339.67V83.47H705.64 M709.64,79.47H335.67v548.78h373.97V79.47L709.64,79.47z"/>
              </g>
              <polygon class="no-fill" points="688.39,615.41 481.98,615.41 480.22,355.51 353.6,354.92 353.6,178.47 416.74,91.18 688.39,91.18
                "/>
              <text transform="matrix(1 0 0 1 415.7879 104.3583)"><tspan x="0" y="0" class="lufga black svg-text-18">After realizing that our team </tspan>
                <tspan x="-15.19" y="21" class="lufga black svg-text-18">did not have a standard UI </tspan>
                <tspan x="-30.38" y="42" class="lufga black svg-text-18">library, and that none of our </tspan>
                <tspan x="-45.57" y="63" class="lufga black svg-text-18">project’s UIs matched the corporate </tspan>
                <tspan x="-60.76" y="84" class="lufga black svg-text-18">style guide, I took it upon myself to </tspan>
                <tspan x="-62.18" y="105" class="lufga black svg-text-18">create and document a design system </tspan>
                <tspan x="-62.18" y="126"
                       class="lufga black svg-text-18">that not only contained a standardized </tspan>
                <tspan x="-62.18" y="147"
                       class="lufga black svg-text-18">(js framework agnostic) css library that </tspan>
                <tspan x="-62.18" y="168"
                       class="lufga black svg-text-18">extended our corporate style guide, but </tspan>
                <tspan x="-62.18" y="189"
                       class="lufga black svg-text-18">also established best practices for UX </tspan>
                <tspan x="-62.18" y="210" class="lufga black svg-text-18">implementation, including WCAG </tspan>
                <tspan x="-62.18" y="231" class="lufga black svg-text-18">compliance. The custom theme that </tspan>
                <tspan x="64.44" y="252" class="lufga black svg-text-18">extends our coordinate </tspan>
                <tspan x="64.58" y="273" class="lufga black svg-text-18">style guide for the web </tspan>
                <tspan x="64.72" y="294" class="lufga black svg-text-18">was based off</tspan>
                <tspan x="64.87" y="315" class="lufga black svg-text-18 yellow2">	</tspan>
                <tspan x="81.82" y="315" class="lufga black svg-text-18 yellow3">		</tspan>
                <tspan x="175.82" y="315" class="lufga black svg-text-18">and its </tspan>
                <tspan x="65.01" y="336" class="lufga black svg-text-18">community fork</tspan>
                <tspan x="65.15" y="357" class="lufga black svg-text-18 yellow2">	</tspan>
                <tspan x="81.82" y="357" class="lufga black svg-text-18 yellow3">		</tspan>
                <tspan x="170.82" y="357" class="lufga black svg-text-18">. I chose </tspan>
                <tspan x="65.29" y="378" class="lufga black svg-text-18">this library because it’s </tspan>
                <tspan x="65.43" y="399" class="lufga black svg-text-18">popular, </tspan>
                <tspan x="65.58" y="420" class="lufga black svg-text-18">well-maintained, </tspan>
                <tspan x="65.72" y="441" class="lufga black svg-text-18">extremely customizable, </tspan>
                <tspan x="65.86" y="462" class="lufga black svg-text-18">and has wrapper </tspan>
                <tspan x="66" y="483" class="lufga black svg-text-18">libraries available for </tspan>
                <tspan x="66.14" y="504" class="lufga black svg-text-18">most js frameworks. </tspan></text>
              <a xlink:href="https://semantic-ui.com/" target="_blank" class="svg-link"><text transform="matrix(1 0 0 1 480 420)" class="lufga black svg-text-18">Semantic UI</text></a>
              <a xlink:href="https://fomantic-ui.com" target="_blank" class="svg-link"><text transform="matrix(1 0 0 1 480.665 461.166)" class="lufga black svg-text-18">Fomantic UI</text></a>
            </g>
            <g id="max-text-yellow" class="animate__animated animate__fadeIn _4">
              <rect x="18.72" y="370.5" class="black" width="448.62" height="306.44"/>
              <g>
                <rect x="1" y="353.31" class="yellow" width="446.62" height="304.44"/>
                <g>
                  <path class="black"
                        d="M446.62,354.31v302.44H2V354.31H446.62 M448.62,352.31H0v306.44h448.62V352.31L448.62,352.31z"/>
                </g>
              </g>
              <rect x="19.32" y="368.7" class="no-fill" width="413.03" height="282.45"/>
              <text transform="matrix(1 0 0 1 19.3175 381.8725)"><tspan x="0" y="0" class="lufga black svg-text-18">I began working at </tspan>
                <tspan x="163.71" y="0" class="lufga black svg-text-18 yellow2">	</tspan>
                <tspan x="180" y="0" class="lufga black svg-text-18 yellow3">	</tspan>
                <tspan x="248" y="0" class="lufga black svg-text-18">in 2017 after </tspan>
                <tspan x="0" y="21.6"
                       class="lufga black svg-text-18">graduating University of Miami with a Bachelors </tspan>
                <tspan x="0" y="43.2"
                       class="lufga black svg-text-18">of Science in Computer Science. I’ve had the </tspan>
                <tspan x="0" y="64.8"
                       class="lufga black svg-text-18">opportunity to explore many of the popular js </tspan>
                <tspan x="0" y="86.4"
                       class="lufga black svg-text-18">frameworks, including spending roughly two </tspan>
                <tspan x="0" y="108"
                       class="lufga black svg-text-18">years working with Angular, about a year with </tspan>
                <tspan x="0" y="129.6"
                       class="lufga black svg-text-18">Vue, and a few small projects with React. I do </tspan>
                <tspan x="0" y="151.2" class="lufga black svg-text-18">have quite a bit of experience with vanilla js and </tspan>
                <tspan x="0" y="172.8"
                       class="lufga black svg-text-18">jQuery as well. I’m grateful to have been able to </tspan>
                <tspan x="0" y="194.4"
                       class="lufga black svg-text-18">try out so many different flavors of js, and to </tspan>
                <tspan x="0" y="216"
                       class="lufga black svg-text-18">have the time to explore new frontend libraries </tspan>
                <tspan x="0" y="237.6" class="lufga black svg-text-18">that I find interesting and useful. I will continue </tspan>
                <tspan x="0" y="259.2"
                       class="lufga black svg-text-18">to look for that quality in any future endeavors. </tspan></text>
              <a xlink:href="https://maximus.com" target="black" class="svg-link"><text transform="matrix(1 0 0 1 184.4698 382.1948)" class="lufga black svg-text-18">Maximus</text></a>
            </g>
            <g id="circles1_3_" class="animate__animated animate__fadeIn _5 ">
              <g>
                <circle class="yellow black-stroke small-stroke" cx="778.35" cy="506.47" r="8.16"/>
                <circle class="yellow black-stroke small-stroke" cx="778.35" cy="483.17" r="8.16"/>
                <circle class="yellow black-stroke small-stroke" cx="778.35" cy="459.86" r="8.16"/>
                <circle class="yellow black-stroke small-stroke" cx="778.35" cy="436.56" r="8.16"/>
                <circle class="yellow black-stroke small-stroke" cx="778.35" cy="413.26" r="8.16"/>
                <circle class="yellow black-stroke small-stroke" cx="778.35" cy="389.95" r="8.16"/>
                <circle class="yellow black-stroke small-stroke" cx="778.35" cy="366.65" r="8.16"/>
                <circle class="yellow black-stroke small-stroke" cx="778.35" cy="343.35" r="8.16"/>
                <circle class="yellow black-stroke small-stroke" cx="778.35" cy="320.05" r="8.16"/>
                <circle class="yellow black-stroke small-stroke" cx="778.35" cy="296.74" r="8.16"/>
                <circle class="yellow black-stroke small-stroke" cx="778.35" cy="273.44" r="8.16"/>
              </g>
              <g>
                <circle class="yellow black-stroke small-stroke" cx="753.37" cy="519.77" r="8.16"/>
                <circle class="yellow black-stroke small-stroke" cx="753.37" cy="496.46" r="8.16"/>
                <circle class="yellow black-stroke small-stroke" cx="753.37" cy="473.16" r="8.16"/>
                <circle class="yellow black-stroke small-stroke" cx="753.37" cy="449.86" r="8.16"/>
                <circle class="yellow black-stroke small-stroke" cx="753.37" cy="426.55" r="8.16"/>
                <circle class="yellow black-stroke small-stroke" cx="753.37" cy="403.25" r="8.16"/>
                <circle class="yellow black-stroke small-stroke" cx="753.37" cy="379.95" r="8.16"/>
                <circle class="yellow black-stroke small-stroke" cx="753.37" cy="356.65" r="8.16"/>
                <circle class="yellow black-stroke small-stroke" cx="753.37" cy="333.34" r="8.16"/>
                <circle class="yellow black-stroke small-stroke" cx="753.37" cy="310.04" r="8.16"/>
                <circle class="yellow black-stroke small-stroke" cx="753.37" cy="286.74" r="8.16"/>
              </g>
            </g>
            <g id="zigzag_5_" class="animate__animated animate__fadeIn _5 ">
              <polygon class="black" points="31.05,335.84 48.61,346.82 41.58,318.72 69.68,311.8 61.78,283.16 90.53,275.91 83.07,248.04
                110.95,241.89 92.73,230.69 65.51,237.5 72.75,266.04 44.22,273.28 53.05,301.68 23.58,308.84 		"/>
              <polygon class="red black-stroke small-stroke" points="25.56,328.73 43.12,339.71 36.09,311.61 64.19,304.69 56.29,276.05 85.04,268.8 77.58,240.93
                105.46,234.78 87.24,223.58 60.02,230.39 67.26,258.93 38.73,266.17 47.57,294.57 18.09,301.73 		"/>
            </g>
            <g id="thunderbolt_3_" class="animate__animated animate__fadeIn _5 ">
              <polygon class="black" points="364.48,35.43 402.53,40.11 394.92,62.94 406.63,65.13 395.5,86.94 405.27,88.88 359.21,154.84
                364.85,101.14 352.77,99.23 362.49,75.9 348.67,73.48 		"/>
              <polygon class="yellow black-stroke small-stroke" points="358.99,31.48 397.04,36.16 389.43,58.99 401.14,61.18 390.02,82.99 399.78,84.93 353.72,150.89
                359.36,97.19 347.28,95.28 357.01,71.95 343.19,69.52 		"/>
            </g>
            <g id="Z_5_" class="animate__animated animate__fadeIn _5 ">
              <polygon class="black" points="679.02,599.33 704.92,586.16 742.36,603.01 730.39,639.72 769.02,653.77 743.12,666.06
                702.29,652.89 715.9,613.38 		"/>
              <polygon class="blue black-stroke small-stroke" points="675.98,592.75 701.88,579.58 739.31,596.43 727.34,633.14 765.98,647.19 740.08,659.48
                699.25,646.31 712.86,606.79 		"/>
            </g>

            <g id="ird_rect">
            <g id="maximus_rect" class="animate__animated animate__fadeIn _4">
                <rect class="black" x="46.46" y="135.17" width="273.28" height="68.68"/>
              <g clip-path="url(#clip-path)">
                    <path class="pure-white"
                          d="M-111.72,133.9l91.52,70.64h-7.43l-91.52-70.64Zm29,0L8.81,204.54h7.43L-75.28,133.9Zm18.22,0L27,204.54h7.43L-57.06,133.9Zm18.22,0,91.53,70.64h7.42L-38.84,133.9Zm18.23,0,91.52,70.64h7.43L-20.61,133.9Zm-72.89,0,91.52,70.64H-2L-93.5,133.9Zm91,0,91.52,70.64H89L-2.54,133.9Zm36.44,0L118,204.54h7.43L33.9,133.9Zm18.23,0,91.52,70.64h7.43L52.13,133.9Zm18.22,0,91.52,70.64h7.43L70.35,133.9Zm18.22,0,91.52,70.64h7.43L88.57,133.9Zm-72.89,0,91.52,70.64h7.43L15.68,133.9Zm90.58,0,91.51,70.64h7.43L106.26,133.9Zm36.42,0,91.51,70.64h7.43L142.68,133.9Zm18.22,0L245,204.54h7.42L160.89,133.9Zm18.21,0,91.51,70.64h7.42L179.1,133.9Zm18.21,0,91.51,70.64h7.42L197.31,133.9Zm-72.85,0,91.51,70.64H216L124.47,133.9Zm90.91,0,91.51,70.64h7.43L215.38,133.9Zm36.42,0,91.51,70.64h7.43L251.8,133.9Zm18.22,0,91.5,70.64h7.43L270,133.9Zm18.21,0,91.51,70.64h7.42L288.22,133.9Zm18.21,0,91.51,70.64h7.42L306.43,133.9Zm-72.85,0,91.51,70.64h7.43L233.59,133.9ZM-83.42,63.25,8.09,133.9h7.42L-76,63.25Zm36.42,0L44.51,133.9h7.42L-39.58,63.25Zm18.21,0L62.72,133.9h7.42L-21.37,63.25Zm18.21,0L80.93,133.9h7.42L-3.15,63.25Zm18.21,0L99.14,133.9h7.43L15.06,63.25Zm-72.84,0L26.3,133.9h7.42L-57.79,63.25Zm90.91,0,91.51,70.65h7.42L33.12,63.25Zm36.42,0,91.51,70.65h7.42L69.54,63.25Zm18.21,0,91.51,70.65h7.42L87.75,63.25Zm18.21,0,91.51,70.65h7.42L106,63.25Zm18.21,0,91.51,70.65h7.43L124.18,63.25Zm-72.84,0,91.51,70.65h7.42L51.33,63.25Z"/>
                </g>
              <rect class="pure-white" x="25.57" y="118.4" width="277.37" height="70.13"/>
              <path class="black" d="M301.94,119.4v68.14H26.57V119.4H301.94m2-2H24.57v72.14H303.94V117.4Z"/>
            </g>
              <g id="ird_logo" class="animate__animated animate__fadeIn _6">
                <g id="mask">
                    <g id="mask-2">
                        <polygon id="path-1" class="pure-white"
                                 points="203.39 143.12 223.22 143.12 223.22 166.57 203.39 166.57 203.39 143.12"/>
                    </g>
                </g>
                <g id="Page-1">
                    <g id="Group" class="purple-maximus">
                        <g id="Group-3">
                            <path id="Fill-1" class="even-odd"
                                  d="M206.54,158.43s3,3.21,6.74,3.21c2.31,0,4.24-.66,4.24-2s-1-1.74-4.43-2.45c-4.42-.94-8.29-2.83-8.29-7.07,0-3.48,3.48-7,9.61-7a14.28,14.28,0,0,1,8.39,2.73L220,150a10.36,10.36,0,0,0-5.56-1.93c-2.12,0-4,.75-4,2.12s1.51,1.69,5.28,2.49c4.42.95,7.58,2.41,7.58,7,0,3.82-4.05,6.88-9.75,6.88a13.92,13.92,0,0,1-10.08-4.05l3.15-4.1"/>
                        </g>
                      <path id="Fill-4" class="even-odd"
                            d="M179,158.09V143.66h5.64v12.88c0,3.29,1.56,4.68,4.42,4.68a5.72,5.72,0,0,0,4.43-2V143.66h5.62V166h-5.64v-3.3a7,7,0,0,1-6.29,3.81,8,8,0,0,1-8.18-8.41"/>
                      <path id="Fill-5" class="even-odd"
                            d="M173.33,151.62V166h-5.66V153.08c0-3.06-1.17-4.71-4-4.71a5.79,5.79,0,0,0-4.38,1.71V166h-5.66V153.08c0-3.06-1.17-4.71-4-4.71a5.79,5.79,0,0,0-4.38,1.71V166h-5.66V143.66h5.67v3.26a6.81,6.81,0,0,1,6.21-3.83c5.37,0,6.92,3.91,6.92,3.91a7.83,7.83,0,0,1,7.12-3.91c4.52,0,7.82,3.16,7.82,8.53"/>
                      <polygon id="Fill-6" class="even-odd"
                               points="127.39 165.99 133.04 165.99 133.04 143.66 127.39 143.66 127.39 165.99"/>
                      <polyline id="Fill-7" class="even-odd"
                                points="117.2 157.43 123.41 166 116.8 166 113.91 162 117.2 157.43"/>
                      <polyline id="Fill-8" class="even-odd"
                                points="117.2 152.2 123.41 143.66 116.83 143.66 113.93 147.66 117.2 152.2"/>
                      <polyline id="Fill-9" class="even-odd"
                                points="107.23 143.66 100.64 143.66 108.72 154.83 100.63 166 107.18 166 112.03 159.34 115.31 154.83 107.23 143.66"/>
                      <path id="Fill-10" class="even-odd"
                            d="M71,151.62V166H65.31V153.08c0-3.06-1.18-4.71-4-4.71a5.83,5.83,0,0,0-4.38,1.68V166H51.26V153.08c0-3.06-1.18-4.71-4-4.71a5.78,5.78,0,0,0-4.38,1.72V166H37.22V143.66h5.66v3.25a6.83,6.83,0,0,1,6.22-3.82C54.47,143.09,56,147,56,147a7.83,7.83,0,0,1,7.12-3.91c4.52,0,7.82,3.16,7.82,8.53"/>
                      <path id="Fill-11" class="even-odd"
                            d="M91,146.73v-3.07h5.67V166H91v-3a7.08,7.08,0,0,1-6,3.61c-5.2,0-9.76-4.85-9.76-11.73s4.56-11.74,9.76-11.74a6.86,6.86,0,0,1,6,3.65l0,3.5a5.58,5.58,0,0,0-4.57-1.86c-3.42,0-5.47,2.78-5.47,6.46s2,6.45,5.47,6.45a5.56,5.56,0,0,0,4.57-1.88v-9.18l0-3.5"/>
                    </g>
                </g>
                <path class="purple-maximus" d="M232.42,165.85v-15h4.94v15Z"/>
                <path class="purple-maximus"
                      d="M251,154.94a3.44,3.44,0,0,0-.64-.12l-.62,0a3.32,3.32,0,0,0-3.35,2.34,3.6,3.6,0,0,0-.18,1v7.68h-4.91V150.9H246v2.16h.06a4.82,4.82,0,0,1,1.62-1.88,4.21,4.21,0,0,1,2.43-.73,3.82,3.82,0,0,1,.59,0,3.89,3.89,0,0,1,.47.09Z"/>
                <path class="purple-maximus"
                      d="M268.06,166.16,266,164a8.42,8.42,0,0,1-2.56,1.82,8.8,8.8,0,0,1-3.79.72,11.22,11.22,0,0,1-2.88-.38,7.32,7.32,0,0,1-2.5-1.2,6.29,6.29,0,0,1-1.75-2,6.08,6.08,0,0,1-.66-2.87,5.92,5.92,0,0,1,.37-2.08,5.82,5.82,0,0,1,1-1.69,6.93,6.93,0,0,1,1.46-1.29,12.41,12.41,0,0,1,1.71-1,8.54,8.54,0,0,1-1.63-2.14,5.39,5.39,0,0,1-.61-2.58,4.81,4.81,0,0,1,.61-2.51,5.42,5.42,0,0,1,1.58-1.72,6.5,6.5,0,0,1,2.18-1,9.83,9.83,0,0,1,2.41-.31,9.4,9.4,0,0,1,2.42.32,6.84,6.84,0,0,1,2.13,1,5.18,5.18,0,0,1,1.5,1.72,5.69,5.69,0,0,1,.22,4.35,5.18,5.18,0,0,1-.94,1.54A7.38,7.38,0,0,1,264.9,154a14.5,14.5,0,0,1-1.69,1l2.73,2.8,2.18-3.35h5.58L269,160.79l5.4,5.37ZM259.21,157a4.85,4.85,0,0,0-1.61,1,2.46,2.46,0,0,0-.72,1.89,2.55,2.55,0,0,0,.26,1.15,2.85,2.85,0,0,0,.68.86,3,3,0,0,0,.93.53,3.06,3.06,0,0,0,1.07.19,3.88,3.88,0,0,0,1.83-.43A4.27,4.27,0,0,0,263,161Zm3.86-7.46a1.94,1.94,0,0,0-1.89-2H261a2.11,2.11,0,0,0-1.49.57,1.9,1.9,0,0,0-.6,1.42,2.82,2.82,0,0,0,.42,1.47,4.9,4.9,0,0,0,1.21,1.36,5.88,5.88,0,0,0,1.78-1.14,2.28,2.28,0,0,0,.72-1.69Z"/>
                <path class="purple-maximus"
                      d="M286.74,165.85V163.9h-.06a4.64,4.64,0,0,1-2.06,1.76,6.81,6.81,0,0,1-8.09-1.8,7.85,7.85,0,0,1-1.4-2.55,9.56,9.56,0,0,1-.48-3,9.2,9.2,0,0,1,.5-3,7.93,7.93,0,0,1,1.41-2.52,6.58,6.58,0,0,1,5.16-2.34,5.8,5.8,0,0,1,2.8.63,6.3,6.3,0,0,1,1.78,1.39h.06v-9.37h4.93v22.74Zm-.18-7.51a4.3,4.3,0,0,0-.26-1.42,3.93,3.93,0,0,0-.73-1.23,3.76,3.76,0,0,0-1.11-.87,3.43,3.43,0,0,0-1.51-.33,3.38,3.38,0,0,0-1.53.33,3.44,3.44,0,0,0-1.79,2.09,4.48,4.48,0,0,0-.23,1.41,4.65,4.65,0,0,0,.23,1.43,3.63,3.63,0,0,0,.68,1.25,3.33,3.33,0,0,0,1.11.85,3.38,3.38,0,0,0,1.53.33,3.29,3.29,0,0,0,1.51-.33,3.53,3.53,0,0,0,1.12-.87,4.14,4.14,0,0,0,1-2.64Z"/>
            </g>
        </g>
            <!--            <g id="max-logo" >
                          <path class="purple-maximus" d="M39.09,112.77h259.62c2.89,0,5.24,2.34,5.24,5.24v72.72c0,2.89-2.34,5.24-5.24,5.24H39.09
                            c-2.89,0-5.24-2.34-5.24-5.24v-72.72C33.85,115.12,36.2,112.77,39.09,112.77z"/>
                          <rect x="211.87" y="132.28" class="pure-white" width="1.76" height="43.32"/>
                          <path class="pure-white" d="M232.87,153.88c-0.93,0-1.69-0.76-1.69-1.69c0-0.93,0.76-1.69,1.69-1.69c0.93,0,1.69,0.76,1.69,1.69
                            C234.54,153.12,233.79,153.86,232.87,153.88z M234.03,174.97h-2.33v-16.45h2.32L234.03,174.97z"/>
                          <path class="pure-white" d="M236.35,163.65c0-1.45-0.07-2.74-0.14-5.14h2.28v3.06h0.07c0.83-2.17,2.95-3.58,5.27-3.48
                            c0.58-0.01,1.16,0.06,1.72,0.21l-0.46,2.28c-0.43-0.13-0.89-0.19-1.34-0.17c-3.51,0-5.09,3.06-5.09,5.27v9.29h-2.32L236.35,163.65
                            z"/>
                          <path class="pure-white" d="M260.32,169.2l5.56,5.77h-3.56l-3.52-3.64c-2.04,2.67-4.22,4.29-7.59,4.29c-4.15,0-7.8-2.63-7.8-7.1
                            c0-3.64,2.67-5.94,5.66-7.35c-1.45-1.59-2.91-3.55-2.91-5.82c0-3.72,2.71-5.87,6.23-5.87c3.3,0,6.02,1.97,6.02,5.59
                            c0,3.27-2.91,5.14-5.48,6.47l5.7,5.86l3.43-5.91h2.84L260.32,169.2z M250.66,162.74c-3.27,1.83-4.5,3.24-4.5,5.82
                            c0,2.81,2.39,4.74,5.09,4.74c2.7,0,4.36-1.61,5.98-3.64L250.66,162.74z M252.42,151.77c-1.95-0.1-3.62,1.4-3.72,3.35
                            c0,0.09-0.01,0.19,0,0.28c0,1.69,1.8,3.76,2.6,4.47c1.9-1.09,4.57-2.28,4.57-4.85c-0.01-1.8-1.47-3.26-3.27-3.26
                            C252.53,151.76,252.47,151.76,252.42,151.77L252.42,151.77z"/>
                          <path class="pure-white" d="M281.89,174.97h-2.32v-2.74h-0.07c-1.5,2.02-3.88,3.19-6.4,3.16c-5.14,0-8.73-3.7-8.73-8.65
                            s3.64-8.65,8.73-8.65c2.52-0.03,4.9,1.15,6.4,3.16h0.07v-12.85h2.32V174.97z M273.1,173.28c3.62,0,6.55-2.93,6.55-6.55
                            c0-3.62-2.93-6.55-6.55-6.55c-3.83,0-6.23,2.84-6.23,6.55c0,3.7,2.41,6.55,6.24,6.55H273.1z"/>
                          <path class="pure-white" d="M86.61,164.34l-12.38-29.02c-0.5-0.91-0.81-1.92-0.92-2.96v-0.07H61.79v0.2c3.03,1.64,2.31,4.28,2.04,6.12
                            l-3.75,27.57c-0.53,3.55-0.92,6.77-3.68,9.21v0.2h10.72v-0.2c-3.1-1.37-2.63-4.28-2.11-8.28l3.75-28.31l12.51,29.68
                            c1.58,3.7,2.5,5.47,5.82,8l14.71-37.74l3.75,31.73c0,2.24-0.39,4.21-2.7,4.93v0.2h12.71v-0.2c-2.63-1.84-2.97-3.64-3.36-6.71
                            l-4.36-32.32c0-1.51,0.47-3.42,2.04-3.88v-0.2H98.53v0.07c-0.08,1.41-0.42,2.79-1,4.07L86.61,164.34L86.61,164.34z"/>
                          <path class="pure-white" d="M141.61,159.32l3.54,9.3c1.77,4.61,2.31,5.34-0.92,6.78v0.2h13.49v-0.2c-3.75-1.91-4.94-5.59-6.45-9.35
                            l-13.67-33.77h-10.4v0.2c3.16,0.98,1.91,3.75,0.99,6.12l-11,27.44c-1.45,3.75-2.56,7.45-6.39,9.35v0.2h12.11v-0.2
                            c-3.16-1.19-2.7-3.09-1.32-6.78l3.43-9.3H141.61z M139.7,154.45h-12.97l6.55-16.59l6.45,16.59L139.7,154.45z"/>
                          <path class="pure-white" d="M171.6,156.77l11,16.52c0.92,1.46,2.54,2.34,4.27,2.31h7.57c-3.75-1.39-5.82-5-7.9-8.16l-10.53-15.67
                            l14.95-19.49H178.9v0.2c4.48,0.53,2.24,4.08,0.6,6.32l-6.55,8.82l-9.51-13.75c-0.65-1.05-1.25-1.59-3.3-1.59h-7.64
                            c2.76,1.26,4.61,3.88,6.31,6.39l9.55,13.82l-18.24,22.91v0.2h12.77v-0.2c-4.02-1.58-2.04-4.4-0.46-6.55l9.15-12.12V156.77z"/>
                        </g>-->
          </g>
        </svg>
        <h1 class="title is-1 has-text-grey-dark is-highlighted white-highlight is-align-self-flex-start animate__animated animate__fadeInDown _2">
          maximus</h1>
      </div>
    </section>
  </b-carousel-item>
</template>

<script>
export default {
  name: "Maximus"
}
</script>
